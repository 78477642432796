import { graphql } from 'gatsby';

export const Fragment = graphql`
  fragment PackageItems on WpPackageConnection {
    edges {
      node {
        id
        featuredImage {
          node {
            altText
            caption
            localFile {
              id
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  height: 510
                  quality: 75
                  transformOptions: { cropFocus: ENTROPY }
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        excerpt
        title
        uri
        lang
        booking {
          bookinginformation
          packagetime
          pricing
          url
        }
      }
    }
  }
`;

export default Fragment;
