import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { useTranslation } from 'react-i18next';
// import { getSrc } from 'gatsby-plugin-image';

// import useLangRedirect from '../hooks/useLangRedirect';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PackageItem from '../components/PackageItem';
import Row from '../components/Row';
import Col from '../components/Col';
import Hero from '../components/Hero';
import TagFilter from '../components/TagFilter';

import { breakpoint } from '../theme';

import { PackageItems, SiteInformation } from '../utils/fragments';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: 0 auto;
  padding-bottom: ${rem(100)};
`;

// const Section = styled(Row)`
//   margin-bottom: ${rem(40)};

//   ${breakpoint('large')} {
//     margin-bottom: ${rem(70)};
//   }
// `;

// const SectionContent = styled(Col)`
//   text-align: center;
//   width: 100%;
//   max-width: ${rem(600)};
//   margin: 0 auto ${rem(28)};

//   ${breakpoint('mediumlarge')} {
//     max-width: ${rem(820)};
//     font-size: ${rem(19)};
//   }
// `;

const Articles = styled(Row)`
  position: relative;
  padding-top: ${rem(20)};
  padding-bottom: ${rem(40)};

  ${breakpoint('large')} {
    padding-bottom: ${rem(70)};
  }
`;

// const Article = styled(ArticleItem)`
//   /* display: block;
//   padding: ${(props) => props.theme.spacing.gutter}; */
//   width: 100%;
//   /* text-align: center; */
// `;

const PackageCol = styled(Col)`
  position: relative;
  z-index: 1;
  display: block;
  margin-bottom: 2.5rem;
  width: 100%;

  ${breakpoint('mediumlarge')} {
    width: 33.333%;
  }

  ${breakpoint('large')} {
    width: 33.333%;
  }
`;

const Filter = styled(Row)`
  margin-top: ${rem(20)};
  width: 100%;
  text-align: center;
`;

function PackageArchive({ data }) {
  const { articles, page, packageCategories } = data;
  const { t } = useTranslation();

  // useLangRedirect(page);

  const featuredArticle = articles?.edges[0]?.node;

  const {
    // featuredImage,
    seo,
  } = page;

  // const featuredMedia = featuredImage?.node;
  // const itemImageSrc = getSrc(featuredMedia?.localFile);
  if (!page || !featuredArticle) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc || seo.opengraphDescription}
        // image={itemImageSrc}
        page={page}
      />
      <Hero
        heading={page.name}
        image={featuredArticle.featuredImage?.node?.localFile}
        pageTitle
        preHeading={t('packagesTitle')}
        tint
      >
        {page.description && (
          <div
            dangerouslySetInnerHTML={{ __html: page.description }} // eslint-disable-line react/no-danger
          />
        )}
      </Hero>

      <Container>
        {packageCategories.edges && (
          <Filter>
            <Col>
              <TagFilter
                showAll={false}
                items={packageCategories.edges.map(({ node: category }) => ({
                  id: category.termTaxonomyId,
                  key: category.id,
                  url: category.uri,
                  title: category.name,
                }))}
                active={page.termTaxonomyId}
              />
            </Col>
          </Filter>
        )}
        <Articles>
          {articles.edges.map(({ node: item }) => (
            <PackageCol key={item.id}>
              <PackageItem
                image={item.featuredImage?.node}
                title={item.title}
                text={item.excerpt}
                subText={item.excerpt}
                button={{ link: item.uri, text: t('readMore') }}
              />
            </PackageCol>
          ))}
        </Articles>
      </Container>
    </Layout>
  );
}

PackageArchive.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

PackageArchive.fragments = [PackageItems, SiteInformation];

export const query = graphql`
  query ($id: String!, $lang: String!) {
    page: wpPackageCategory(id: { eq: $id }) {
      id
      termTaxonomyId
      uri
      name
      description
      seo {
        breadcrumbs {
          text
          url
        }
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        schema {
          raw
        }
        title
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
      }
    }
    articles: allWpPackage(
      sort: { fields: menuOrder, order: ASC }
      filter: {
        packageCategories: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      ...PackageItems
    }
    packageCategories: allWpPackageCategory(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          id
          termTaxonomyId
          name
          count
          slug
          uri
          taxonomy {
            node {
              name
              id
              archivePath
            }
          }
          description
        }
      }
    }
    site {
      ...SiteInformation
    }
  }
`;

export default PackageArchive;
